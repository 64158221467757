import { IonPage, IonContent, IonGrid, IonRow, IonCol } from '@ionic/react'
import React, { useRef, useEffect } from 'react'
import type { Swiper as SwiperInterface } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/scss'
import '@ionic/react/css/ionic-swiper.css'

import LoginPage from '../auth/LoginPage'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { AppState } from '../../store/redux/types'
import { RegionState, setLanguage, setRegion } from '../../store/redux/slices/regionSlice'
import WelcomeSlide from './WelcomeSlide'
import WelcomeAlmostThereSlide from './WelcomeAlmostThereSlide'
import { Region } from '../../types/interfaces'
import { API_BASE_REGION } from '../../constants/defaults'
import { useAppDispatch } from '../../hooks/reduxHooks'

const WelcomePage: React.FC = () => {
  const dispatch = useAppDispatch()
  const { name, selectedLanguage } = useSelector((state: AppState): RegionState => state.region)
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const slideParam = queryParams.get('slide')
  const initialSlideParam = slideParam ? parseInt(slideParam, 10) : 2
  const initialSlide = !name || !selectedLanguage ? 0 : initialSlideParam

  const swiperRef = useRef<SwiperInterface | null>(null)
  const contentRef = useRef<HTMLIonContentElement | null>(null)

  const scrollToTop = () => {
    contentRef.current?.scrollToTop(500)
  }

  useEffect(() => {
    const selectedRegion: Region = API_BASE_REGION.find((el: Region): boolean => el.apiCode === 'spain') as Region
    const regionData: Region = {
      name: selectedRegion.name,
      apiCode: selectedRegion.apiCode,
      code: selectedRegion.code,
      timeZone: selectedRegion.timeZone,
    }
    dispatch(setRegion(regionData))
    dispatch(setLanguage('es-ES'))
  }, [dispatch])

  return (
    <IonPage className="loginScreen">
      <IonContent className="ion-padding" fullscreen ref={contentRef}>
        <IonGrid>
          <IonRow>
            <IonCol size="12" sizeMd="6" offsetMd="3">
              <Swiper
                onSwiper={(swiper: SwiperInterface) => {
                  swiperRef.current = swiper
                }}
                initialSlide={initialSlide}
                noSwipingClass={'swiper-no-swiping'}
              >
                <SwiperSlide>
                  <WelcomeSlide swiperInstance={swiperRef.current!} scrollToTop={scrollToTop} />
                </SwiperSlide>
                <SwiperSlide>
                  <WelcomeAlmostThereSlide swiperInstance={swiperRef.current!} scrollToTop={scrollToTop} />
                </SwiperSlide>
                <SwiperSlide>
                  <LoginPage />
                </SwiperSlide>
              </Swiper>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  )
}

export default WelcomePage
