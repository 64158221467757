import { IonRouterOutlet } from '@ionic/react'
import { Redirect, Route } from 'react-router'
import React from 'react'

import useStatusBarStyle from '../../hooks/useStatusBarStyle'
import WelcomePage from '../welcome/WelcomePage'
import SignUpPage from './SignUpPage'

const UnauthenticatedApp: React.FC = () => {
  useStatusBarStyle()
  return (
    <IonRouterOutlet>
      <Route exact path="/login" component={WelcomePage} />
      <Route exact path="/signup" component={SignUpPage} />
      <Route render={() => <Redirect to="/login" />} />
    </IonRouterOutlet>
  )
}

export default UnauthenticatedApp
