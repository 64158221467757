import React, { useState } from 'react'
import {
  IonPage,
  IonContent,
  IonProgressBar,
  IonGrid,
  IonRow,
  IonCol,
  IonToolbar,
  IonButtons,
  IonTitle,
  IonHeader,
  IonButton,
} from '@ionic/react'
import UserDetailsForm from './signup/UserDetailsForm'
import AgreementsForm from './signup/AgreementsForm'
import Confirmation from './signup/Confirmation'
import { useTranslation } from 'react-i18next'
import { Capacitor } from '@capacitor/core'
import LogoImage from '../../components/LogoImage'
import { AgreementState } from '../../types/types'

const SignUpPage: React.FC = () => {
  const { t } = useTranslation()
  const [step, setStep] = useState(1)
  const [agreementsAccepted, setAgreementsAccepted] = useState<AgreementState>({
    gdpr: false,
    eula: false,
    volunteer: false,
  })

  const nextStep = () => setStep((prev) => prev + 1)
  const prevStep = () => setStep((prev) => prev - 1)

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton routerDirection="back" routerLink="/login?slide=2">
              {t('btn.back')}
            </IonButton>
          </IonButtons>
          <IonTitle>{t('signup.signup')}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonProgressBar value={step / 3} />
        <IonGrid>
          <IonRow className="ion-justify-content-center">
            <IonCol sizeMd="6">
              <h2>&nbsp;</h2>
              {Capacitor.getPlatform() === 'ios' && <div>&nbsp;</div>}
              <LogoImage />
              {step === 1 && <UserDetailsForm nextStep={nextStep} />}
              {step === 2 && (
                <AgreementsForm
                  nextStep={nextStep}
                  prevStep={prevStep}
                  agreementsAccepted={agreementsAccepted}
                  setAgreementsAccepted={setAgreementsAccepted}
                />
              )}
              {step === 3 && <Confirmation prevStep={prevStep} />}
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  )
}

export default SignUpPage
